import { FC } from 'react';
import IconProps from './IconProps';

const DotIcon: FC<IconProps & { pulse?: boolean }> = (props) => {
  const { onClick, className, pulse } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="5" fill="currentColor" />
      {pulse && <circle cx="10" cy="10" r="8" className="animate-pulse" fill="currentColor" fillOpacity="0.3" />}
    </svg>
  );
};

export default DotIcon;
